import React from "react";
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer } from "@react-pdf/renderer";
import { useSelector } from 'react-redux';

const styles = StyleSheet.create({
    viewRow: {
        flexDirection: 'row',
        fontSize: 11,
        justifyContent: 'space-between',
        alignContent: 'center',
    },
    viewRow2: {
        flexDirection: 'row',
        fontSize: 11,
        alignContent: 'center',
        justifyContent: 'flex-start',
    },
    view2: {
        fontSize: 11,
        justifyContent: 'space-between',
        flexDirection: 'column',
        marginTop: '20px',
    },
    text1: {
        fontSize: 11,
        marginBottom: '10px',
    },
    text5: {
        fontSize: 11,
        marginLeft: '5px',
        marginRight: '5px',
    },
    text6: {
        fontSize: 11,
        marginTop: '10px',
        marginBottom: '10px',
    },
    text7: {
        fontSize: 11,
        width: '40%',
        marginTop: '20px',
    },
    h1: {
        fontSize: 14,
        fontStyle: 'bold',
        marginBottom: '10px',
        marginTop: '20px',
    },
    text3: {
        fontSize: 11,
        marginTop: '5px',
        marginBottom: '5px',
    },
    text4: {
        fontSize: 11,
        paddingTop: '6px',
        marginTop: '20px',
        borderTop: '1px solid black',
    },
    text2: {
        fontSize: 11,
        paddingTop: '6px',
        borderTop: '1px solid black',
    },
    page: {
        fontFamily: "Helvetica",
        padding: 35,
        display: "flex",
        flexDirection: "column",
        width: '100%'
    },
    header: {
        textAlign: "center",
    },
    section: {
        marginBottom: 10,
    },
    image: {
        width: '150px',
        height: '40px',
        marginBottom: '10px'
    },
    textHeader: {
        width: '100%',
        fontSize: 11,
        fontWeight: "900",
        textAlign: "center",
    },
    tableContainer: {
        width: '100%',
        alignSelf: 'center',
        border: '1px solid black', // Adicionado borda à tabela
        marginBottom: '35px',

    },

    row: {
        flexDirection: 'row',
        borderBottom: '1px solid black', // Adicionado borda inferior à linha
    },
    cell: {
        fontSize: 11,
        padding: 3,
        paddingRight: 20,
        paddingLeft: 20,
        width: '40%',
    },
    cell1: {
        width: '70%',
        fontSize: 11,
        padding: 3,
        paddingRight: 20,
        paddingLeft: 20,
        borderLeftWidth: '1px',
        borderLeftColor: 'black',
    },
    cell2: {
        width: '70%',
        fontSize: 9,
        padding: 3,
        paddingRight: 20,
        paddingLeft: 20,
        borderRightWidth: '1px',
        borderRightColor: 'black',
    },
    cell3: {
        width: '100%',
        fontSize: 9,
        padding: 3,
        paddingRight: 20,
        paddingLeft: 20,
        borderWidth: '1px',
        borderColor: 'black',
    },

    headerCell: {
        fontWeight: 'bold',
    },
    textBold: {
        fontWeight: 'bold',
    },
    textLine: {
        textDecoration: 'underline',
        fontSize: 11,
        marginTop: '10px',
        marginBottom: '10px',
    },
    textCenter: {
        textAlign: 'center',
        fontSize: 11,
    },
    centerH1: {
        textAlign: 'center',
        fontSize: 14,
        fontStyle: 'bold',
    },
    downTable: {
        width: '100%',
        alignSelf: 'center'
    },
    th: {
        textAlign: 'right',
        marginTop: 20,
        marginBottom: 20,
        fontWeight: 'bold',
        flex: 1, // Adicionado flex
    },
    td: {
        fontSize: 11,
        flex: 1, // Adicionado flex
    },
    tr: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
    },
    centralizado: {
        textAlign: 'center',
        fontSize: 11,
        fontWeight: 'extrabold',
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1, // Adicionado flex

        paddingRight: '7px'
    },
    centralizadoTh: {
        textAlign: 'center',
        fontWeight: 'bold',
        flex: 1, // Adicionado flex
        height: 50,
        verticalAlign: "center",
        paddingTop: 10,

    },
    alinhamentoDireita: {
        textAlign: 'right',
        flex: 1, // Adicionado flex
    },
    alinhamentoDireitaTd: {
        textAlign: 'right',
        fontSize: 11,
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1, // Adicionado flex

        paddingRight: '7px'
    },
    textParagraph: {
        fontSize: 11,
        marginBottom: '5px',
        marginTop: '5px',
        textAlign: 'justify',
        paddingLeft: '33px',
        paddingRight: '33px',
    },
    textParagraph1: {
        fontSize: 11,
        marginBottom: '10px',
        marginTop: '10px',
        textAlign: 'justify',
        paddingLeft: '25px',
        paddingRight: '25px',
    }
});

const formatDate = (inputDate) => {
    const months = [
        "janeiro", "fevereiro", "março", "abril", "maio", "junho",
        "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
    ];

    const dateParts = inputDate.split("-");
    const year = dateParts[0];
    const month = months[Number(dateParts[1]) - 1];
    const day = Number(dateParts[2]);

    return `${day} de ${month} de ${year}`;
};
const SingulareComGarantia = ({ dados }) => {

    const allInfoArray = useSelector((state) => state.finalTotal.finalTotal);
    const calcData = useSelector((state) => state.calcData.calcData);
    const valorTotal = allInfoArray[6]
    const lugarEData = `${dados.adress}, ${formatDate(dados.vencimentoDate)}`;
    const now = new Date();
    const parcelas = allInfoArray[4]
    const dias = parcelas * 30;
    const emissao = `${dados.emissao}`;
    const razaoSocial = dados.razaoSocial;
    const credorCnpj = dados.cnpj;
    const currentZoom = window.devicePixelRatio;
    const pdfViewerWidth = 2500 / currentZoom;
    const pdfViewerHeight = 1200 / currentZoom;


    return (
        <PDFViewer width={'90%'} height={'85%'}>
            <Document>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                    </View>
                    <View style={styles.section}><Text style={[styles.textLine]}>CONTRATO DE ALIENAÇÃO FIDUCIÁRIA DE BENS MÓVEIS EM GARANTIA E OUTRAS AVENÇAS</Text>
                        <Text style={[styles.text3]}>1.        CREDOR FIDUCIÁRIO:</Text>
                        <Text style={[styles.textParagraph]}>
                            SMARTCRED FUNDO DE INVESTIMENTO EM DIREITOS CREDITÓRIOS, inscrito no CNPJ/ME nº
                            34.027.549/0001-91, instituição financeira com sede na Cidade de São Paulo, Estado de São Paulo, na Av.
                            Avenida Brigadeiro Faria Lima, 1355, 5ª andar – CEP 01452-002, na forma de seus atos constitutivos, e-mail:
                            analise3@creditosmart.com.br;
                        </Text>
                        <Text style={[styles.text3]}>2 . DEVEDOR FIDUCIANTE:</Text>
                        <Text style={[styles.textParagraph]}>
                            INOX FORM INDÚSTRIA E COMÉRCIO LTDA, inscrito no CNPJ/ME nº 12.371.421/0001-08, com sede na Cidade
                            de Guarulhos, Estado de São Paulo, na Rua Regiane, 261 – Cumbica – CEP 07180-190, na forma de seus atos
                            constitutivos, e-mail: rose@inoxform.com.br ;
                        </Text>
                        <Text style={[styles.text3]}>2.1. FIEL DEPOSITÁRIO:</Text>
                        <Text style={[styles.textParagraph]}>
                            As Partes elegem o ADONIAS ELIAS DA SILVA, brasileiro, empresário, residente e domiciliado no município de
                            Guarulhos/SP, na Avenida Bartolomeu de Carlos, 675 - Apto 2310/Bloco B – Jardim Flor da Montanha – CEP
                            07097-420, portador do CPF/ME nº 261.511.538-39 e RG nº 277.476-29, email: angela@inoxform.com.br ,
                            para atuar na qualidade de fiel depositária, pela guarda e conservação das mercadorias efetivamente
                            recebidas para depósito, nos termos do Código Civil.
                        </Text>
                        <Text style={[styles.text3]}>3. GARANTIA REAL:</Text>
                        <Text style={[styles.textParagraph]}>
                            Alienação fiduciária dos equipamentos industriais, devidamente discriminados no Anexo I, (os “Bens
                            Móveis”):
                        </Text>
                        <Text style={[styles.text3]}>4. VALOR ATRIBUÍDO AOS BENS MÓVEIS:</Text>
                        <Text style={[styles.textParagraph]}>
                            {valorTotal}
                        </Text>
                        <Text style={[styles.text6]}>
                            6. DÍVIDA TOTAL GARANTIDA: {valorTotal}
                        </Text>
                        <Text style={[styles.text6]}>7. PRAZO: Durante o prazo de vigência da Obrigação Garantida descrita no item 5, com a possibilidade de
                            pagamento e vencimento antecipados após 1º mês da presente data, conforme previsto nos ajustes
                            integrantes das Obrigações Garantidas</Text>
                        <Text style={[styles.text6]}>8. ENCARGOS: aqueles textualmente descritos e acordados nas respectivas Obrigações Garantidas
                            mencionadas no item 6, quais sejam: Taxa de juros de mora de 1,5% (um e meio por cento) ao mês, além de
                            correção monetária calculada com base no índice IGP-M e multas contratuais que atingem até 10% (dez por
                            cento).</Text>
                        <View style={styles.viewRow2}><Text style={styles.textLine}>Cláusula 1ª: </Text><Text style={styles.text6}> DA GARANTIA FIDUCIÁRIA</Text>
                        </View>
                        <Text style={[styles.textParagraph]}>
                            1.1. Para fins de garantia do efetivo cumprimento das obrigações principais e acessórias assumidas pelo
                            Devedor Fiduciante, decorrentes das Obrigações Garantidas descritas no item 5 do Resumo da Operação,
                            acima, o Devedor Fiduciante aliena, neste ato, em favor da Credors Fiduciária, os Bens Móveis descritos no
                            item 3 do Resumo da Operação, acima, transferindo-lhes a propriedade fiduciária na forma do artigo 1.361 e
                            seguintes da Lei Federal nº 10.406, de 10 de janeiro de 2002 (Código Civil).
                        </Text>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                    </View>
                    <View style={styles.section}>


                        <Text style={[styles.textParagraph]}>1.2. Considerar-se-ão garantidos os negócios ou operações já celebradas e pendentes de liquidação e
                            aqueles que vierem a ser ajustados, desde que, em qualquer hipótese, sejam derivados das Obrigações
                            Garantidas.</Text>
                        <Text style={[styles.textParagraph]}> 1.3. Considerar-se-ão igualmente derivados das Obrigações Garantidas os termos de recompra,
                            confissões de dívida e outros ajustes referentes a eventual saldo devedor das operações indicadas no item 6
                            do Resumo da Operação, acima.</Text>

                        <Text style={[styles.textParagraph]}> 1.4. A presente alienação fiduciária garantirá o efetivo e pontual cumprimento das Obrigações
                            Garantidas até o valor limite indicado no item 4 do Resumo da Operação, acima.</Text>
                        <Text style={[styles.textParagraph]}>1.5. Eventual saldo devedor remanescente das Obrigações Garantidas deverá ser cobrado pela Credora
                            Fiduciária por meio de instrumentos e/ou garantias adicionais pertinentes.</Text>
                        <View style={styles.viewRow2}><Text style={styles.textLine}>Cláusula 2ª: </Text><Text style={styles.text6}> Da abrangência da garantia fiduciária</Text>
                        </View>
                        <Text style={[styles.textParagraph]}>
                            2.1. A garantia abrange os Bens Móveis descritos e caracterizados no item 3 do Resumo da Operação,
                            acima, e identificados na declaração anexada ao presente instrumento (Anexos I). Constituída a propriedade
                            fiduciária dos Bens Móveis em favor da Credora Fiduciária, a partir do registro do presente contrato no
                            Cartório de Registro de Títulos e Documentos do domicílio da Devedora Fiduciante, desdobrar-se-á a posse dos
                            Bens Móveis, de tal sorte que a Devedora Fiduciante permancerá como possidora direta dos Bens Móveis e a
                            Credora Fiduciária tornar-se-á possuidora indireta dos mesmos bens.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            2.2. Integrarão a Garantia todos os acessórios e pertences que forem integrados aos bens móveis
                            descritos no Anexo I, de forma inseparável ou não, a fim de aumentar sua utilidade ou facilitar sua exploração
                            econômica, não competindo à Devedora Fiduciante qualquer direito de retenção com relação a tais acessórios
                            e pertences.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            2.3. A Devedora Fiduciante não poderá, sem o prévio consentimento por escrito da Credora Fiduciária ,
                            total ou parcialmente, (i) alienar, ceder, ou de qualquer outra forma transferir as Garantias, (ii) criar, incorrer,
                            ou permitir a criação de quaisquer Ônus sobre as Garantias (ressalvado o ônus constituído neste Contrato) ou
                            qualquer opção em favor de terceiros ou qualquer ação de terceiros com relação as Garantias, nem sobre
                            qualquer direito a ele relativo, ou (iii) celebrar qualquer contrato ou compromisso que restrinja o direito ou a
                            possibilidade de a Credora Fiduciária vender, ceder ou transferir as Garantias.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            2.4. O fiel depositário assume a responsabilidade pela guarda e controle do bem objeto da presente
                            garantia, cientes das responsabilidades daí decorrentes, nos termos dos artigos 627 e seguintes do Código Civil
                            Brasileiro e legislação complementar
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            2.5. O fiel depositário assume e aceita, ainda, as responsabilidades inerentes à conservação das
                            garantias, sujeitando-se às sanções daí decorrentes, não podendo dispor a qualquer título ou alterar o local de
                            depósito do bem até que sejam integralmente quitadas as Obrigações Garantidas, sem a prévia e expressa
                            anuência por escrito da Credora.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            2.6. Sem prejuízo do acima disposto, a Devedora Fiduciante irá:
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            a. no prazo de 5 (cinco) Dias Úteis a partir da presente data, apresentar à Credora Fiduciária o comprovante
                            de registro deste Contrato para registro perante os Cartórios de Registro de Títulos e Documentos do
                            domicílio das Partes, confirmando que as Garantias foram alienadas fiduciariamente à Credora Fiduciária e
                            registrados como direito de garantia real de primeiro grau sem concorrência de terceiros e que não existe
                            nenhum outro direito de garantia real com relação a tais Garantias; e
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            b. atender de forma diligente quaisquer exigências que tais cartórios venham a fazer com relação ao registro
                            deste Contrato no menor prazo possível.
                        </Text>
                        </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                    </View>
                    <View style={styles.section}>
                        <Text style={[styles.textParagraph]}>
                            2.7. Sem prejuízo das obrigações da Devedora Fiduciante, a Devedora Fiduciante, neste ato, outorga
                            em favor de Credora Fiduciária, mandato para agir em seu nome com o fim específico de tomar todas as
                            providências necessárias para realizar o registro do presente Contrato perante os Cartórios de Registro de
                            Títulos e Documentos competentes e cumprir todas as exigências feitas pelos respectivos Oficiais de Registro.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            2.8. Todos e quaisquer custos, despesas, taxas e/ou tributos necessários para as averbações e registros
                            aqui previstos serão de responsabilidade única e exclusiva da Devedora Fiduciante. Não obstante, caso a
                            Credora Fiduciária venha a providenciar os registros e demais formalidades aqui previstas em nome da
                            Devedora Fiduciante, a Devedora Fiduciante desde já reconhece como sendo líquidas, certas e exigíveis as
                            notas de débito que venham a ser emitidas pela Credora Fiduciária para pagamento desses custos e/ou
                            despesas. Os eventuais registros do presente Contrato efetuados pela Credora Fiduciária, não isentam o
                            descumprimento de obrigação não pecuniária pela Devedora Fiduciante, nos termos dos Instrumentos de
                            Crédito.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            2.9. Realizado o registro do presente contrato perante o órgão competente, a posse da Garantia será
                            desdobrada, conferindo-se a posse direta à Devedora Fiduciante, e a posse indireta à Credora Fiduciária.
                        </Text>
                        <View style={styles.viewRow2}><Text style={styles.textLine}>Cláusula 3ª: </Text><Text style={styles.text6}>Dos direitos e deveres da Devedora Fiduciante</Text>
                        </View>

                        <Text style={[styles.textParagraph]}>
                            3.1. Desde que cumpridas as Obrigações Garantidas, a Devedora Fiduciante poderá utilizar livremente
                            os Bens Móveis por sua conta e risco, respondendo integralmente por tributos eventualmente incidentes
                            sobre a propriedade, posse ou utilização dos Bens Móveis e por quaisquer eventos que impliquem diminuição
                            de seu valor para efeitos das Obrigações Garantidas.
                        </Text>
                        <Text style={[styles.textParagraph]}>

                            3.2. Se a Credora Fiduciária vier a pagar quaisquer tributos que eventualmente incidam ou venham a
                            incidir  sobre  a  propriedade,  posse  ou  utilização  dos  Bens  Móveis  ou  quaisquer  encargos  ou  despesas
                            decorrentes da propriedade, posse ou utilização dos Bens Móveis, a Devedora Fiduciante deverá reembolsá-
                            las em 30 (trinta) dias contados do recebimento de sua notificação para essa finalidade, aplicando-se nessa
                            hipótese as mesmas penalidades pela mora ou inadimplemento.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            3.3. A Credora Fiduciária reserva-se no direito de, a qualquer tempo, com periodicidade e mediante
                            aviso com 15 (quinze) dias de antecedência, exigir comprovantes de pagamento dos referidos encargos fiscais
                            ou tributários, ou de quaisquer outras contribuições, ou ainda, conforme o caso, a comprovação de provisão
                            dos valores eventualmente não pagos.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            3.4. Na hipótese dos Bens Móveis deteriorarem-se ou se tornarem insuficientes ou impróprios para
                            assegurar o cumprimento das Obrigações Garantidas, a Devedora Fiduciante deverá apresentar à Credora
                            Fiduciária novos bens em substituição ou reforço da Garantia Fiduciária, no prazo de 10 (dez) dias corridos
                            contados do recebimento de notificação das Credoras Fiduciárias nesse sentido, sob pena de ser decretado o
                            vencimento antecipado das Obrigações Garantidas.
                        </Text>

                        <Text style={[styles.textParagraph]}>
                            3.5. Caso  a  Credora  Fiduciária  não  aceite  os  novos  bens  eventualmente  oferecidos  pela  Devedora
                            Fiduciante, poderão a Credora Fiduciária considerar vencidas antecipadamente as Obrigações Garantidas.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            3.6. O Devedor Fiduciante não poderá transmitir os direitos de que sejam titulares sobre as Garantias a
                            terceiros, sem a expressa anuência das Credoras Fiduciárias.
                        </Text>
                        <View style={styles.viewRow2}><Text style={styles.textLine}>Cláusula 4ª: </Text><Text style={styles.text6}>Do vencimento automático</Text>
                        </View>
                        <Text style={[styles.textParagraph]}>
                            4.1. Além das hipóteses previstas em lei e nos respectivos instrumentos das Obrigações Garantidas,
                            considerar-se-ão  automaticamente  vencidas  as  Obrigações  Garantidas,  tornando-se  exigível  a  Dívida  Total
                            Garantida e exequível o gravame ora pactuado:
                        </Text>
                        </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                    </View>
                    <View style={styles.section}>
                        <Text style={[styles.textParagraph1]}>
                            a. Se  o  Devedor  Fiduciante  deixarem  de  cumprir  qualquer  das  obrigações  a  seu  cargo,  oriundas  das
                            Obrigações Garantidas;
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            b. Se  vier  a  ser  rescindido  qualquer  dos  contratos  ou  operações  objeto  das  Obrigações  Garantidas
                            descritas no item 6 do Resumo da Operação, acima;
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            c. Se o Devedor Fiduciante vier a celebrar outros ajustes ou constituir quaisquer ônus, dívidas, gravames
                            ou garantia que, de qualquer forma, incidam ou venham a incidir sobre a Garantia Real em favor de
                            terceiros, sem a prévia e expressa anuência da Credora Fiduciária;
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            d. Se o Devedor Fiduciante protesto de qualquer título ou verificado qualquer outro evento que indique
                            mudança  na  situação  econômica-financeira  e  solvabilidade  do  Devedor  Fiduciante,  capaz  de
                            comprometer a higidez da Garantia Real; ou
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            e. Se for movida medida judicial que possa afetar a Garantia Real.
                        </Text>
                        <View style={styles.viewRow2}><Text style={styles.textLine}>Cláusula 5ª: </Text><Text style={styles.text6}>Das disposições da Lei Federal n  4.728/65</Text>
                        </View>
                        <Text style={[styles.textParagraph]}>
                            5.1 Para fins do disposto no artigo 66-B da Lei Federal nº 4.728/65, as partes estabelecem que:
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            a. O Valor Total da Dívida é o indicado no item 7 Divida Total Garantida, acima;
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            b. O  prazo  para  o  cumprimento  das  Obrigações  Garantidas  é  o  indicado  no  item  8  do  Resumo  da
                            Operação,  acima,  ressalvadas  as  hipóteses  de  vencimento  antecipado  com  a  possibilidade  de
                            vencimento antecipado e automático estipuladas no presente instrumento;
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            c. Os encargos decorrentes da mora são os previstos no item 9 do Resumo da Operação, acima;
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            d. A taxa de juros, a cláusula penal, o índice de atualização monetária, as comissões e demais encargos,
                            se houver, são os estabelecidos nos itens 9 Resumo das Operações, acima.
                        </Text>
                        <View style={styles.viewRow2}><Text style={styles.textLine}>Cláusula 6ª: </Text><Text style={styles.text6}> Do termo de quitação</Text>
                        </View>
                        <Text style={[styles.textParagraph]}>
                            6.1. Em 30 (trinta) dias contados da data de liquidação da Dívida Total Garantida, a Credoras Fiduciárias
                            fornecerão  o  respectivo  termo  de  quitação  à  Devedora  Fiduciante,  sob  pena  de  multa  em  favor  desta
                            equivalente a 0,5% (meio por cento) ao mês, ou fração, sobre o valor da Dívida Total Garantida.
                        </Text>
                        <View style={styles.viewRow2}><Text style={styles.textLine}>Cláusula 7ª: </Text><Text style={styles.text6}> Da consolidação da propriedade em favor das Credoras Fiduciárias</Text>
                        </View>
                        <Text style={[styles.textParagraph]}>
                            7.1. Vencida e não paga a dívida no prazo estabelecido no item 8 do Resumo da Operação, acima, ou
                            resolvida  antecipadamente  quaisquer  das  Obrigações  Garantidas  nas  hipóteses  previstas  nos  respectivos
                            contratos, a Credora Fiduciária, independentemente de leilão, hasta pública, avaliação prévia ou qualquer
                            outra medida judicial ou extrajudicial, poderá vender os Bens Móveis a terceiros, pelo valor atribuido, devendo
                            aplicar  o  preço  da  venda  no  pagamento  de  seu  crédito,  e  das  despesas  incorridas  com  sua  cobrança,
                            entregando à Devedora Fiduciante eventual saldo, prestando-lhe as devidas contas.
                        </Text>
                        </View>
                        </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                    </View>
                    <View style={styles.section}>
                        <Text style={[styles.textParagraph]}>
                            7.2. Se o produto da venda dos Bens Móveis não bastar para pagar o crédito e as despesas apuradas, o
                            Devedor Fiduciante continuará pessoalmente obrigada a pagar o saldo devedor apurado.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            7.3. Para os fins do presente contrato, entende-se por:
                        </Text>
                        
                
                        <Text style={[styles.textParagraph1]}>
                            a. Dívida: o saldo devedor dos negócios decorrentes das Obrigações Garantidas, na data da venda a terceiros,
                            acrescido dos juros convencionais, penalidades, encargos contratuais e de eventuais despesas incorridas com
                            os reparos necessários à reposição dos Bens Móveis em idêntico estado ao existente nesta data, a menos que
                            a Devedora Fiduciante já os tenha devolvido em tais condições às Credoras Fiduciárias ou a quem os tenha
                            adquirido, acrescido tambem das despesas com a cobrança do saldo devedor e 10% (dez por cento) do saldo
                            devedor a título de honorários advocatícios;
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            b. Despesas: a soma das importâncias correspondentes aos encargos e eventuais custas de intimação e as
                            necessárias à venda dos Bens Móveis a terceiros.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            7.4. Em até 5 (cinco) dias após a venda dos Bens Móveis a terceiros, a Credora Fiduciária entregará o Devedor
                            Fiduciante  a  importância  que  eventualmente  exceder  ao  saldo  devedor  na  data  da  venda  a  terceiros,
                            computadas a Dívida e as Despesas, tal como acima definidas.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            7.5. Se o preço da venda dos Bens Móveis não alcançar o valor da Dívida e das Despesas, o saldo deverá ser
                            pago à Credora Fiduciária pelo DevedoreFiduciante mediante a execução das Notas Promissórias emitidas em
                            função das Obrigações Garantidas ou da cobrança do presente contrato, pelo saldo, ou mediante a excussão das
                            eventuais garantias complementares.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            7.6. Se  não  houver  interessados  em  adquirir  os  Bens  Móveis,  a  Credora  Fiduciária  poderá  pleitear  sua
                            adjudicação em seu benefício, pelo preço definido no item 5 do Resumo da Operação, acima. Se o preço da
                            adjudicação não alcançar o valor integral da dívida e das despesas, o saldo deverá ser pago à Credora Fiduciária
                            pela  Devedores  Fiduciante  mediante  execução  da  Nota  Promissória  emitida  em  função  das  Obrigações
                            Garantidas ou da cobrança do presente contrato, pelo saldo, ou mediante a excussão das eventuais garantias
                            complementares.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            7.7. As partes reconhecem que a hipótese de adjudicação dos Bens Móveis, conforme a hipótese da cláusula
                            7.6, acima, constitui faculdade contratual lícita e permitida em lei, já que não decorrerá automaticamente da
                            falta de pagamento da Dívida pelo Devedor Fiduciante, mas da ausência de interessados em adquirir os Bens
                            Móveis, tendo a Credora Fiduciária envidado seus melhores esforços no sentido de efetivamente vender a
                            terceiros os Bens Móveis antes de pleitear a adjudicação em seu favor.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            7.8. Para fins da transmissão da propriedade fiduciária:
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            a. O Devedor Fiduciante declara não haver nenhum ônus ou medida judicial de caráter real ou pessoal
                            ou reipersecutória que possa atingir os Bens Móveis;
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            b. As Credoras Fiduciárias dispensam a apresentação de certidões fiscais nos termos do § 2º, do artigo
                            1º, do Decreto Federal nº 93.240/86;
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            c. O Devedor Fiduciante declara que não pesa sobre os Bens Móveis nenhuma restrição de ordem
                            urbanística ou ambiental;
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            d. O Devedor Fiduciante declara ainda, sob as penas da lei, que não está pessoalmente vinculada à
                            Previdência  Social  como  empregadora  e  que  não  é  contribuinte  na  qualidade  de  produtora  rural,  não
                            estando, portanto, sujeito às obrigações previdenciárias abrangidas pelo Instituto Nacional da Seguridade
                            Social (INSS);
                        </Text>
                        <Text style={[styles.textParagraph1]}>
                            e. O Devedor Fiduciante declara ter interesse econômico no cumprimento das Obrigações Garantidas.
                        </Text>
                        <View style={styles.viewRow2}><Text style={styles.textLine}>Cláusula 8ª: </Text><Text style={styles.text6}>Das declarações do Devedora Fiduciante</Text>
                        </View>
                        <Text style={[styles.textParagraph]}>
                            8.1. O  Devedor  Fiduciante  declara  que  os  Bens  Móveis  alienados  fiduciariamente  são  de  sua  legítima
                            propriedade,  posse  e  domínio  a  justo  título,  encontrando-se  em  perfeito  estado  de  conservação  e/ou
                            funcionamento,  livres  e  desembaraçados  de  quaisquer  ônus,  dívidas,  dúvidas  e/ou  gravames  e  pendências
                            judiciais ou extrajudiciais.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            8.2. O Devedor Fiduciante declara tambem conhecer os termos das Obrigações Garantidas, isto é, os contratos
                            descritos no Resumo da Operação, acima, sob a denominação de Obrigações Garantidas, assinando o presente
                            instrumento como principal pagadora, solidariamente responsável pelo cumprimento das obrigações pactuadas
                            e  assumidas  nesse  e  naqueles  instrumentos,  permanecendo  íntegras  suas  responsabilidades  até  o  total  e
                            definitivo cumprimento das obrigações avençadas.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            8.3. O Devedor Fiduciante compromete-se a atender a eventuais exigências do Cartório de Registro de Títulos e
                            Documentos relacionadas ao registro do presente contrato.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            8.4. O Devedor Fiduciante, por esta cláusula, nomeia a Credora Fiduciária para representá-la na prática de atos
                            específicos,  com  a  única  finalidade  de  dar  eficácia  ao  registro  da  alienação  fiduciária  do  Imóvel  perante  o
                            Cartório de Registro de Títulos e Documentos competente, atendendo eventuais exigências.
                        </Text>
                        </View>
                                        <Text style={[styles.textParagraph]}>
                            8.5. A falta (a) de atendimento de eventuais exigências do Cartório competente ou (b) de substituição dos Bens
                            Móveis por outro(s) idôneo(s) e de valor equivalente, no prazo de 15 (quinze) dias, inclusive na hipótese de
                            constatação de qualquer tipo de irregularidade ou restrição sobre os Bens Móveis, capaz de reduzir seu valor,
                            implicará vencimento antecipado do presente contrato, sujeitando a Devedora Fiduciante aos efeitos da mora.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            8.6. As  Partes  autorizam  e  determinam,  desde  já,  que  o  Sr.  Oficial  do  Serviço  de  Registro  de  Títulos  e
                            Documentos competente proceda, total ou parcialmente, a todos os assentamentos, registros e averbações
                            necessários  decorrentes  do  presente  Contrato,  isentando-o  de  qualquer  responsabilidade  pelo  devido
                            cumprimento do disposto neste instrumento.
                        </Text></Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                    </View>
                    <View style={styles.section}>

                        <View style={styles.viewRow2}><Text style={styles.textLine}>Cláusula 9ª: </Text><Text style={styles.text6}>Das disposições finais.</Text>
                        </View>
                        <Text style={[styles.textParagraph]}>
                            9.1. Fica desde logo estipulado que o presente Contrato revoga e substitui todo e qualquer entendimento
                            havido entre as Partes anteriormente a esta data sobre o mesmo objeto.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            9.2. Todas  as  comunicações  entre  as  Partes  serão  consideradas  válidas  a  partir  de  seu  recebimento  nos
                            endereços constantes do preâmbulo deste Contrato, ou em outros que venham a indicar, por escrito, no curso
                            desta relação. As comunicações serão consideradas entregues quando recebidas sob protocolo, com “aviso de
                            recebimento”  expedido  pela  Empresa  Brasileira  de  Correios  e  Telégrafos,  por  fax  ou  por  telegrama  nos
                            endereços acima. Os originais dos documentos enviados por fax deverão ser encaminhados para os endereços
                            acima em até 5 (cinco) dias úteis após o envio da mensagem. Cada Parte deverá comunicar imediatamente a
                            outra sobre a mudança de seu endereço sob pena de serem consideradas como válidas as notificações enviadas
                            para os endereços que até então sejam de conhecimento das Partes.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            9.3. As  custas  referentes  ao  registro  do  presente  instrumento  perante  os  órgãos  competentes  serão
                            despendidas pelo Devedor Fiduciante.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            9.4. O presente instrumento é firmado em caráter irrevogável e irretratável, obrigando as partes contratantes,
                            seus herdeiros e sucessores a qualquer título.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            9.5. As Partes comprometem-se a cumprir os requisitos da presente cláusula e da legislação de proteção de
                            dados aplicável no Brasil, incluindo, mas não se limitando à Lei nº 13.709, de 14 de agosto de 2018 (“Lei Geral
                            de Proteção de Dados Pessoais” ou “LGPD”), como também se comprometem a garantir que seus empregados,
                            agentes, prepostos, representantes legais, contratados, subcontratados, terceiros relacionados observem seus
                            dispositivos.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            9.6. Em observância a Lei Geral de Proteção de Dados, o Devedor Fiduciante declara que o tratamento de seus
                            dados  pessoais,  inclusive,  se  aplicável,  dados  pessoais  sensíveis  pelas  demais  partes,  deu-se  a  pedido  do
                            Devedor Fiduciante, pois necessário, nos procedimentos necessário para a celebração do presente instrumento.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            9.7. As Partes declaram a veracidade de seus endereços eletrônicos, bem como autorizam o uso para todos os
                            atos diretamente relacionados a este instrumento, conforme a regulamentação aplicável.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            9.8. As  Partes  conferem  expressa  anuência  para  que  o  Contrato  seja  celebrado  por  meio  de  assinaturas
                            eletrônicas, nos termos do artigo 10, da Medida Provisória nº 2200-2, de 24 de agosto de 2001, devendo, em
                            casos de contingência, ser firmado de forma impressa.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            9.9. Ao  assinarem  por  meio  de  assinaturas  eletrônicas,  as  partes  declaram  a  integridade,  autenticidade  e
                            regularidade do Contrato.
                        </Text>
                        <Text style={[styles.textParagraph]}>
                            9.10. As partes elegem o Foro da Comarca de São Paulo para dirimir quaisquer dúvidas ou questões
                            oriundas deste instrumento, com renúncia expressa a quaisquer outros, por mais privilegiados que sejam ou
                            venham a se tornar.
                        </Text>
                        <Text style={[styles.text1, { marginTop: '60px', marginLeft: '20px', justifyContent: 'flex-start', width: '50%' }]}>
                            {lugarEData}
                        </Text>
                        </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                    </View>
                    <View style={styles.section}>
                        
                        <View style={styles.viewRow}>
                            <Text style={[styles.text2, { marginTop: '60px', marginLeft: '20px', justifyContent: 'flex-start', width: '40%' }]}>
                                Credora Fiduciária{'\n'}
                                {dados.razaoSocial}
                            </Text></View>
                        <View style={styles.viewRow}>
                            <Text style={[styles.text2, { marginTop: '60px', marginLeft: '20px', justifyContent: 'flex-start', width: '40%' }]}>
                                Devedor Fiduciante: {'\n'}
                                {dados.razaoSocial}
                            </Text>
                        </View>
                        <View style={styles.viewRow}>
                            <Text style={[styles.text2, { marginTop: '60px', marginLeft: '20px', justifyContent: 'flex-start', width: '40%' }]}>
                                FIEL DEPOSITÁRIO: {'\n'}
                                {dados.name}
                            </Text>
                        </View>
                        <View style={styles.viewRow}>
                            <Text style={[styles.text2, { marginTop: '60px', marginLeft: '20px', justifyContent: 'flex-start', width: '40%' }]}>
                                Avalista:{'\n'}
                                {dados.name}
                            </Text>
                        </View>
                        <View style={styles.viewRow}>
                            <Text style={[styles.text2, { marginTop: '60px', marginLeft: '20px', justifyContent: 'flex-start', width: '40%' }]}>
                                Avalista:{'\n'}
                                {dados.name}
                            </Text>
                        </View>
                        <View style={styles.viewRow}>
                            <Text style={[styles.text2, { marginTop: '60px', marginLeft: '20px', justifyContent: 'flex-start', width: '40%' }]}>
                                Avalista:{'\n'}
                                {dados.name}
                            </Text>
                        </View>
                        <View style={styles.viewRow}>
                            <Text style={styles.text7}>
                                1ª Testemunha:{'\n'}
                                Rosana Winterink{'\n'} CPF/ME nº 222.201.218-01
                            </Text>
                            <Text style={styles.text7}>
                                2ª Testemunha:{'\n'} Júlio Cesar de Lima Kamezawa{'\n'}
                                CPF/ME nº 402.361.038-06
                            </Text>
                        </View>
                    </View>
                </Page>
                <Page style={styles.page}>
                    <View style={styles.header}>
                        <Image src="https://i.ibb.co/By78Cjp/singulare-logo.png" style={styles.image} />
                    </View>
                    <View style={styles.anexo}>
                        <Text style={styles.centerH1}>
                            ANEXO I - BENS IMÓVEIS
                        </Text>
                    </View>
                </Page>
            </Document>
        </PDFViewer >
    )
}

export default SingulareComGarantia;
