import React, { useEffect, useState } from "react";
import '../styles/Investidors.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaTrashCan } from "react-icons/fa6";
import UpdateInvestor from '../utils/Modals/UpdateInvestor';
import { GrDocumentUpdate } from "react-icons/gr";
import { BsEye } from "react-icons/bs";
import InvestidorModal from "./InvestidorModal";
import { FaEdit } from "react-icons/fa";

const Investidores = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [selectedEmissor, setSelectedEmissor] = useState(null);
    const [selectedInvestidor, setSelectedInvestidor] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [investidores, setInvestidores] = useState([]);
    const [modalInvestorIsOpen, setModalInvestorIsOpen] = useState(false);
    const [detailsInvestor, setDetailsInvestor] = useState(false);
    const ITEMS_PER_PAGE = 5; // Quantidade de itens por página
    const [currentPage, setCurrentPage] = useState(1); // Página atual

    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentInvestidores = investidores.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(investidores.length / ITEMS_PER_PAGE);

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => setCurrentPage(i)}
                    disabled={currentPage === i}
                >
                    {i}
                </button>
            );
        }
        return pages;
    };

    const toggleModal = () => {
        setModalInvestorIsOpen(!modalInvestorIsOpen);
    }

    const openInvestorModal = (index) => {
        
        setSelectedItemIndex(index);
        setSelectedInvestidor(investidores[index]); //atualiza com investidor selecionado
        toggleModal();
    }
    const closeInvestorModal = () => {
        setDetailsInvestor(false);
    }

    const userRef = user.data.email;

    useEffect(() => {
        if (selectedItemIndex !== null) {
            const selectedEmissorData = investidores[selectedItemIndex];
            const investidorTransformado = transformarInvestidor(selectedEmissorData);
            setSelectedEmissor(investidorTransformado);
        } else {
            setSelectedEmissor(null);
        }
    }, [selectedItemIndex, investidores]);

    useEffect(() => {
        const fetchInvestidores = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/investidor?userRef=${userRef}`);
                setInvestidores(response.data);
            } catch (error) {
                setInvestidores([]);
                console.error('Erro ao buscar investidores:', error);
            }
        };

        fetchInvestidores();
    }, []);

    const navigate = useNavigate()

    const handleSave = (investidor) => {
        localStorage.setItem('investidor', JSON.stringify(investidor));
        window.alert('Investidor Selecionado!');
        navigate('/Emissor');
    }
    const handleDeleteInfos = (index) => {
        setSelectedItemIndex(index);
        setShowConfirmation(true);
    }
    const toggleDetails = (index) => {
        console.log(index,'index')
        setSelectedItemIndex(index);
        setDetailsInvestor(true);
    }
    const handleDelete = (investidorId) => {
        const url = process.env.REACT_APP_BASE_URL;
        const headers = {
        }
        if (investidorId) {
            axios.delete(`${url}/investidor/${investidorId}`, headers)
                .then((response) => {
                    if (response.status === 204) {
                        setDeleteSuccess(true);
                        setTimeout(() => {
                            setDeleteSuccess(false);
                        }, 3000);
                        setSelectedItemIndex(null);
                        setShowConfirmation(false);

                        axios.get(`${url}/investidor?userRef=${userRef}`)
                            .then((response) => {
                                const updatedInvestidores = response.data;
                                setInvestidores(updatedInvestidores);
                            })
                            .catch((error) => {
                                console.error('Erro ao fazer a solicitação:', error);
                            });
                    } else {
                        setDeleteError(true);
                        setTimeout(() => {
                            setDeleteError(false);
                        }, 3000);
                    }
                })
                .catch((error) => {
                    console.error('Erro ao fazer a solicitação:', error);
                });
        }
    }

    function transformarInvestidor(investidor) {
        if (investidor.nome && investidor.nome2 && investidor.email) {
            const investidorTransformado = {
                ...investidor,
                representantes: [
                    { nome: investidor.nome, email: investidor.email }
                ]
            }

            // Se 'nome2' existir, adicione um segundo representante
            if (investidor.nome2) {
                investidorTransformado.representantes.push({
                    nome: investidor.nome2,
                    email: investidor.email
                })
            }

            return investidorTransformado;
        }

        return investidor; // Retorna o objeto original se não houver transformação
    }

    return (
        <div className="detailsInvestidor">
            <div className="divTitleInvestidor">
                <h2 className="investidorMessage1">Selecione o investidor para emitir uma Nota Comercial</h2>
                {investidores.length > 0 ? (
                    <table className="investidorTable">
                        <thead>
                            <tr>
                                <th>Razão Social</th>
                                <th>CNPJ</th>
                                <th>E-mail</th>
                                <th>Telefone</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentInvestidores.map((investidor, index) => (
                                <tr key={investidor._id}>
                                    <td>{investidor.razaoSocial.length > 20 ? investidor.razaoSocial.slice(0, 20) + '...' : investidor.razaoSocial}</td>
                                    <td>{investidor.cnpj}</td>
                                    <td>{investidor.representantes[0].email}</td>
                                    <td>{investidor.telefone}</td>
                                    <td>
                                        <div className="tableIcons">
                                            <button onClick={() => toggleDetails(index + indexOfFirstItem)} className="updateButton">
                                                <BsEye color="#1a2f67" size={20} />
                                            </button>
                                            <p className="confirmationMessage">
                                                <button className="deleteButton" onClick={() => handleDeleteInfos(index)}> <FaTrashCan size={20} /></button>
                                            </p>
                                            <button className="updateButton" onClick={() => openInvestorModal(index + indexOfFirstItem)}><FaEdit size={20} /></button>
                                            {modalInvestorIsOpen && (
                                                <UpdateInvestor
                                                    isOpen={modalInvestorIsOpen}
                                                    onClose={() => {
                                                        setModalInvestorIsOpen(false)
                                                        //console.log(investidores[selectedItemIndex], 'masoqueeeeee')
                                                    }}
                                                    investorData={selectedInvestidor}
                                                />
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {showConfirmation &&
                                <div className="">
                                    Confirmar exclusão de {investidores[selectedItemIndex]?.razaoSocial}?
                                    <button className="newButtonInves" onClick={() => handleDelete(investidores[selectedItemIndex]._id)}>Sim</button>
                                    <button className="newButtonInves" onClick={() => setShowConfirmation(false)}>Não</button>
                                </div>}
                        </tbody>
                    </table>

                ) : (
                    <div className="investidorDiv1">Nenhum investidor encontrado</div>
                )}

                <div className="pagination">
                    <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                        Anterior
                    </button>
                    {renderPageNumbers()}
                    <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
                        Próximo
                    </button>
                </div>
            </div>
            {detailsInvestor && (
                <InvestidorModal
                    selectedEmissor={selectedEmissor}
                    handleSave={handleSave}
                    onClose={closeInvestorModal}
                />
            )}
        </div>
    );
}

export default Investidores;
