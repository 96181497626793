import jsPDF from 'jspdf';
import 'jspdf-autotable';

const generatePDFPretacao = (dados, onBlobReady) => {
  const margin = 10; // margem em milímetros
  const pdf = new jsPDF({
    unit: 'mm',
    format: 'a4',
    putOnlyUsedFonts: true,
    orientation: 'portrait',
    marginLeft: margin,
    marginRight: margin,
  });
  const now = new Date();
  const emissor = JSON.parse(localStorage.getItem('emissor')); //avalistas
  const investidor = JSON.parse(localStorage.getItem('investidor')); //investidor
  const garantia = JSON.parse(localStorage.getItem('garantiaDataArray'));
  const dadosBancarios = `Banco: ${emissor.banco}/Agência: ${emissor.agency}/Conta:${emissor.conta}`;
  //const garantias = localStorage.getItem('garantiaDataArray'); //investidor
  //console.log(garantia,'garantias')
  const allInfoArray =  dados.allInfoArray
  let calcData = dados.calcData
  const valorTotal = allInfoArray[13]
  const linhaDoVencimento = calcData[calcData.length - 1]
  const dataDoVencimento = linhaDoVencimento[1] < 10 ? `0${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}` : `${linhaDoVencimento[1]}/${linhaDoVencimento[2]}/${linhaDoVencimento[3]}`;
  const dataHoje = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`;
  const numero = require('numero-por-extenso');
  const formValues = JSON.parse(localStorage.getItem('formValues'));
  const base = formValues.dias === 30 ? 360 : 252;
  const lugarEData = `${investidor.cidade}, ${dataHoje}`;
  // Largura do contêiner disponível com base nas margens
 const containerWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
  let yPosition = 4; // Posição inicial do eixo y

 
  const imageUrl = 'https://i.ibb.co/By78Cjp/singulare-logo.png';
  const leftMargin = 10; // Margem esquerda
  const imageWidth = 42;
  const imageHeight = 12;
  pdf.addImage(imageUrl, 'PNG', leftMargin, yPosition, imageWidth, imageHeight);
//Titulo
  yPosition += 2; // Adicione algum espaçamento abaixo do título
  const fontSizePadrao = 13;
  pdf.setFontSize(fontSizePadrao); // Tamanho da fonte
  pdf.setFont('helvetica', 'normal'); // Estilo da fonte
  const text = `  CONDIÇÕES NEGOCIAIS DO INSTRUMENTO PARTICULAR DE PRESTAÇÃO DE                                SERVIÇOS DE ESCRITURAÇÃO E AGENTE DE LIQUIDAÇÃO`
  pdf.text(text, margin, yPosition + imageHeight + 12, { maxWidth: containerWidth });

  yPosition += 18; // Adicione algum espaçamento abaixo do título
   
 
  const rows = [
    ['Número do Termo','1              '],
    // Adicione mais linhas conforme necessário
  ];
  
 //Tabela 1
  pdf.autoTable({
   // head: [columns1],
    body: rows,
    startY:  yPosition += 20, // Posição inicial do eixo y
    theme: 'grid', // Outros temas disponíveis: 'grid', 'plain', 'striped'
    styles: {
      fontSize: 11, // Tamanho da fonte da tabela
      body: {
        textColor: [255, 0, 0], // Cor do texto do corpo (vermelho neste exemplo)
      },
      textColor: '#00000', // Cor do texto do corpo (vermelho neste exemplo)
      
      // Adicione outros estilos conforme necessário
    },
    columnStyles: {
      0: { cellWidth: 50 }, // Largura da primeira coluna
      1: { cellWidth: 130 }, // Largura da segunda coluna
    },
  });
  yPosition += 15 // Adicione algum espaçamento abaixo do título
  pdf.setFontSize(10)
  pdf.setFont('helvetica', 'bold');
  const text1 = `   CONTRATANTE / EMISSORA`
  pdf.text(text1, margin, yPosition, { maxWidth: containerWidth });

  yPosition += 2 // Adicione algum espaçamento abaixo do título
  pdf.setFont('helvetica', 'bold');
  //Rua Luiz Laraya, 16401-127 - Vila Azevedo - Lins/SP CEP: 16401-127
  const rows1 = [
    [`Nome: `,`${emissor.razaoSocial}`],
    [`CNPJ: `,`${emissor.cnpj}`],
    [`Endereço: `,`${emissor.rua},${emissor.numero} - ${emissor.bairro} - ${emissor.cidade} / ${emissor.estado} CEP: ${emissor.cep}`],
    [`Email: `,`${emissor.email}`],
    [`Dados Bancários: `,`${dadosBancarios}`]
    
    // Adicione mais linhas conforme necessário
  ];
  
 //Tabela 1
  pdf.autoTable({
   // head: [columns1],
    body: rows1,
    startY:  yPosition+2 , // Posição inicial do eixo y
    theme: 'grid', // Outros temas disponíveis: 'grid', 'plain', 'striped'
    styles: {
      fontSize: 11, // Tamanho da fonte da tabela
      body: {
        textColor: [255, 0, 0], // Cor do texto do corpo (vermelho neste exemplo)
      },
      textColor: '#00000', // Cor do texto do corpo (vermelho neste exemplo)
      
      // Adicione outros estilos conforme necessário
    },
    columnStyles: {
      0: { cellWidth: 50 }, // Largura da primeira coluna
      1: { cellWidth: 130 }, // Largura da segunda coluna
    },
  });

  yPosition = pdf.autoTable.previous.finalY + 10;
  /*pdf.setFontSize(10)
  pdf.setFont('helvetica', 'bold');
  const text2 = `   RESPONSÁVEL FINANCEIRO DA CONTRATANTE / EMISSORA`
  pdf.text(text2, margin, yPosition, { maxWidth: containerWidth });

  yPosition += 2 // Adicione algum espaçamento abaixo do título
  pdf.setFont('helvetica', 'bold');
  //Rua Luiz Laraya, 16401-127 - Vila Azevedo - Lins/SP CEP: 16401-127
  const rows2 = [
    [`Nome: `,`Rosana Winterink`],
    [`CNPJ: `,`222.201.218-01`],
    [`Endereço: `,`Rua Silvestre Vasconcelos Calmon 51, sala 1612`],
    [`Email: `,`rosana@c2cards.digital`],
    
    
    // Adicione mais linhas conforme necessário
  ];
  
 //Tabela 1
  pdf.autoTable({
   // head: [columns1],
    body: rows2,
    startY:  yPosition+2 , // Posição inicial do eixo y
    theme: 'grid', // Outros temas disponíveis: 'grid', 'plain', 'striped'
    styles: {
      fontSize: 11, // Tamanho da fonte da tabela
      body: {
        textColor: [255, 0, 0], // Cor do texto do corpo (vermelho neste exemplo)
      },
      textColor: '#00000', // Cor do texto do corpo (vermelho neste exemplo)
      
      // Adicione outros estilos conforme necessário
    },
    columnStyles: {
      0: { cellWidth: 50 }, // Largura da primeira coluna
      1: { cellWidth: 130 }, // Largura da segunda coluna
    },
  });
/////////*/
yPosition = pdf.autoTable.previous.finalY + 10;
pdf.setFontSize(10)
pdf.setFont('helvetica', 'bold');
const text3 = `   DADOS DO ATIVO`
pdf.text(text3, margin, yPosition, { maxWidth: containerWidth });

yPosition += 2 // Adicione algum espaçamento abaixo do título
pdf.setFont('helvetica', 'bold');
//Rua Luiz Laraya, 16401-127 - Vila Azevedo - Lins/SP CEP: 16401-127
const rows3 = [
  [`Natureza do Ativo: `,`Nota Comercial`],
  [`Série: `,`Única`],
  [`Emissão: `,`${dados.dados.emissao}`],
  [`Data de Emissão: `,`${dataHoje}`],
  [`Data de Vencimento: `,`${dataDoVencimento}`],
  [`Valor da Operação: `,`${allInfoArray[6]}`],
  
  
  // Adicione mais linhas conforme necessário
];

//Tabela 1
pdf.autoTable({
 // head: [columns1],
  body: rows3,
  startY:  yPosition+2 , // Posição inicial do eixo y
  theme: 'grid', // Outros temas disponíveis: 'grid', 'plain', 'striped'
  styles: {
    fontSize: 11, // Tamanho da fonte da tabela
    body: {
      textColor: [255, 0, 0], // Cor do texto do corpo (vermelho neste exemplo)
    },
    textColor: '#00000', // Cor do texto do corpo (vermelho neste exemplo)
    
    // Adicione outros estilos conforme necessário
  },
  columnStyles: {
    0: { cellWidth: 50 }, // Largura da primeira coluna
    1: { cellWidth: 130 }, // Largura da segunda coluna
  },
});
/////////////////////
yPosition = pdf.autoTable.previous.finalY + 10;
pdf.setFontSize(10)
pdf.setFont('helvetica', 'bold');
const text4 = `   SERVIÇOS CONTRATADOS`
pdf.text(text4, margin, yPosition, { maxWidth: containerWidth });

yPosition += 2 // Adicione algum espaçamento abaixo do título
pdf.setFont('helvetica', 'bold');
//Rua Luiz Laraya, 16401-127 - Vila Azevedo - Lins/SP CEP: 16401-127
const rows4 = [
  [`X `,`1. Serviço de Escrituração`],
  [`X `,`2. Agente de Liquidação`],
  // Adicione mais linhas conforme necessário
];

//Tabela 1
pdf.autoTable({
 // head: [columns1],
  body: rows4,
  startY:  yPosition+2 , // Posição inicial do eixo y
  theme: 'grid', // Outros temas disponíveis: 'grid', 'plain', 'striped'
  styles: {
    fontSize: 11, // Tamanho da fonte da tabela
    body: {
      textColor: [255, 0, 0], // Cor do texto do corpo (vermelho neste exemplo)
    },
    textColor: '#00000', // Cor do texto do corpo (vermelho neste exemplo)
    
    // Adicione outros estilos conforme necessário
  },
  columnStyles: {
    0: { cellWidth: 50 }, // Largura da primeira coluna
    1: { cellWidth: 130 }, // Largura da segunda coluna
  },
});
///////////////////////////////
//yPosition = pdf.autoTable.previous.finalY + 10;
pdf.addPage();
let yPosition2 = 4
//imagem cabecalho
const imageUrl2 = 'https://i.ibb.co/By78Cjp/singulare-logo.png';
const leftMargin2 = 10; // Margem esquerda
const imageWidth2 = 42;
const imageHeight2 = 12;
pdf.addImage(imageUrl2, 'PNG', leftMargin2, yPosition2, imageWidth2, imageHeight2);

yPosition2 += 20 // Adicione algum espaçamento abaixo do título
pdf.setFontSize(10)
pdf.setFont('helvetica', 'bold');
const text5 = `   REMUNERAÇÃO`
pdf.text(text5, margin, yPosition2, { maxWidth: containerWidth });

yPosition2 += 2 // Adicione algum espaçamento abaixo do título
pdf.setFont('helvetica', 'bold');
//Rua Luiz Laraya, 16401-127 - Vila Azevedo - Lins/SP CEP: 16401-127
const rows5 = [
  [`Remuneração Escrituração`,`0,15% do Valor de Aquisição do Ativo`],
  [`Periodicidade`,`Parcela única`],
  //[`Taxa de Implantação de Liquidante`,`N/A`],
  //[`Periodicidade`,`N/A`],
 // [`Índice de correção`,`IPCA`],
  [`Data de pagamento`,`5º dia útil após a data de emissão`],
  [`Alíquota de Imposto(no caso  de escrituração)`,`Os valores referidos acima  serão acrescidos dos valores dos tributos que incidem sobre a prestação desses serviços (gross up), tais como: \n (i) Imposto Sobre Serviços de Qualquer Natureza  - ISSQN; \n (ii) Contribuição para o Programa de Integração Social - PIS; \n iii) Contribuição para o Financiamento da Seguridade Social`],
  [``,`- COFINS; Imposto sobre a Renda e Proventos de qualquer Natureza – IR e (iv) outros tributos que venham a incidir sobre a remuneração da Singulare, nas respectivas alíquotas vigentes a cada data de pagamento, sendo certo que serão acrescidos aos pagamentos valores adicionais, de modo que a Singulare  receba os mesmos valores que seriam recebidos caso nenhum dos impostos elencados neste item.`],
  // Adicione mais linhas conforme necessário
];

//Tabela 1
pdf.autoTable({
 // head: [columns1],
  body: rows5,
  startY:  yPosition2 + 2 , // Posição inicial do eixo y
  theme: 'grid', // Outros temas disponíveis: 'grid', 'plain', 'striped'
  styles: {
    fontSize: 11, // Tamanho da fonte da tabela
    body: {
      textColor: [255, 0, 0], // Cor do texto do corpo (vermelho neste exemplo)
    },
    textColor: '#00000', // Cor do texto do corpo (vermelho neste exemplo)
    
    // Adicione outros estilos conforme necessário
  },
  columnStyles: {
    0: { cellWidth: 50 }, // Largura da primeira coluna
    1: { cellWidth: 130 }, // Largura da segunda coluna
  },
});
/////////////////
yPosition = pdf.autoTable.previous.finalY + 10;
pdf.setFontSize(11); // Troca para o tamanho de fonte 16 para o próximo texto
pdf.setFont('helvetica', 'bold');
const text6 = `Ao assinar as Condições Negociais, a Contratante declara que recebeu a cópia, leu e compreendeu o Contrato de Prestação de Serviços de Escrituração e Agente de Liquidação registrado eletronicamente no 5° Oficial de Registro de Títulos e Documentos da Comarca de São Paulo – SP sob nº 1.629.613, em 16 de janeiro de 2023 e estão cientes de que, juntamente com a Singulare Corretora de Títulos e Valores Mobiliários S.A. se vincularão a ele integralmente em todos os seus termos e condições`
pdf.text(text6, margin, yPosition, { maxWidth: containerWidth });

yPosition= pdf.autoTable.previous.finalY + 45
const text7 = `${lugarEData}`
pdf.text(text7, margin, yPosition, { maxWidth: containerWidth });
/////
yPosition += 10
pdf.setFontSize(12); // Troca para o tamanho de fonte 16 para o próximo texto
pdf.setFont('helvetica', 'bold');
const text8 = `SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.`
pdf.text(text8, margin, yPosition, { maxWidth: containerWidth });
////////////////////
yPosition += 10
pdf.setFont('helvetica', 'normal');
const text9 = `____________________________________         ____________________________________    \n           Nome: Daniel Doll Lemos                                    Nome: Leandro Mendes Davanso`
pdf.text(text9, margin, yPosition, { maxWidth: containerWidth });
/////////////////////////////////
yPosition += 25
pdf.setFont('helvetica', 'normal');
const text10 = `CONTRATANTE/EMISSORA   \n \n_____________________________________ \n  Nome: Vinicius João Moraes     \n  Cargo: Gerente`
pdf.text(text10, margin, yPosition, { maxWidth: containerWidth });

yPosition += 38
/*pdf.setFont('helvetica', 'normal');
const text11 = `RESPONSÁVEL FINANCEIRO   \n \n_____________________________________ \n  Nome: Rosana Winterink     \n  Cargo: Responsável Financeira`
pdf.text(text11, margin, yPosition, { maxWidth: containerWidth });*/



  const blob = pdf.output('blob');
  onBlobReady(blob);
};


export default generatePDFPretacao;
