import React, { useState } from "react";
import "../../styles/ShowNotes.css";
import { MdSimCardDownload } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";

const ShowNotes = ({ note, onClose }) => {
    const fieldTranslation = {
        _id: "ID",
        userRef: "Referência do Usuário",
        status: "Status",
        valorOperacao: "Valor da Operação",
        parcelas: "Parcelas",
        vencimento: "Data de Vencimento",
        razaoEmissor: "Razão do Emissor",
        createdAt: "Data de Criação",
        __v: "Versão",
    };

    const keyTranslation = {
        urlMinuta: "Minuta",
        urlContrato: "Contrato",
        urlCondicoes: "Condições",
        urlFaturamento: "Relatório de Faturamento",
        urlGarantias: "Garantias",
        urlDocumentos: "Documentos",
    };

    const urlKeys = Object.keys(note).filter((key) => key.startsWith("url"));
    const nonUrlKeys = Object.keys(note).filter((key) => !key.startsWith("url"));

    const [currentPage, setCurrentPage] = useState(1);

    const nextPage = () => {
        setCurrentPage(2);
    };

    const previousPage = () => {
        setCurrentPage(1);
    };

    return (
        <div className="showNotes">
            <div className="showNotes-content">

                <h2 className="modalH2">Detalhes da Nota
                    <div className="close-button2" onClick={onClose}>&times;</div>
                </h2>


                {currentPage === 1 && (
                    <table className="table">
                        <tbody>
                            {nonUrlKeys.map((key) => (
                                <tr key={key} className="table-row">
                                    <td className="table-cell table-key">
                                        {fieldTranslation[key] ? fieldTranslation[key] : key}:
                                    </td>
                                    <td className="table-cell table-value">{note[key]}</td>
                                    <AiFillCheckCircle style={{ color: "green", height: "40px", width: "40px" }} />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {currentPage === 2 && (
                    <table className="table">
                        <tbody>
                            {urlKeys.map((key) => (
                                <tr key={key} className="table-row">
                                    <td className="table-cell table-key">
                                        Baixar {keyTranslation[key] ? keyTranslation[key] : key}:
                                    </td>
                                    <td className="table-cell table-value">
                                        <a href={note[key]} target="_blank" rel="noopener noreferrer">
                                            <MdSimCardDownload className="iconEmitted" size={20} />
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                <div className="pagination">
                    {currentPage === 1 && (
                        <button onClick={nextPage} className="page-button">
                            Próxima Página
                        </button>
                    )}
                    {currentPage === 2 && (
                        <button onClick={previousPage} className="page-button">
                            Página Anterior
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShowNotes;
