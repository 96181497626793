import React from 'react';
import { useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Link,
  Image,
  Font,
} from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import capaImage from '../../images/capa_singulare_new.png';
import contraCapa from '../../images/contra_capa.png';
import page_1 from '../../images/page_01.png';
import final from '../../images/final.png';
import logo from '../../images/trustee.jpg';
import logoParceiro from '../../images/logo_parceiro.png';
import calibriFont from '../../fontes/calibri.ttf';
import arial_bold from '../../fontes/Arial_Bold.ttf';
import arial from '../../fontes/Arial.ttf';

Font.register({ family: 'Roboto', src: calibriFont, fontWeight: 'ultrabold'});
Font.register({ family: 'Arial_Bold', src: arial_bold});
Font.register({ family: 'Arial', src: arial});


const styles = StyleSheet.create({
  // ... (seu estilo existente)

  coverPage: {
    position: 'relative',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch', // Ajusta o tamanho da imagem para cobrir toda a altura da página
    justifyContent: 'stretch', // Ajusta o tamanho da imagem para cobrir toda a largura da página
  },

  coverImage: {
    width: '100%',
    height: '100vh',
  },
  headerImage: {
    width: '25%',
    height:'22',
    paddingTop: '10'
  },
  logoParceiro: {
    width: '10%',
    height:'65',
    paddingTop: '10'
  },
  coverOverlayContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%', // Usando os 20% restantes para o texto
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Adicione um fundo escuro transparente
    boxSizing: 'border-box',
  },
  coverTextContainer: {
    position: 'absolute',
    top: 180,
    left: '20%',
    width: '70%',
    height: '25%', // Usando os 20% restantes para o texto
    boxSizing: 'border-box',
  },
  coverTextContainer_3: {
    position: 'absolute',
    top: 15,
    left: '3%',
    width: '90%',
    boxSizing: 'border-box',
  },
  coverTextContainer_4: {
    position: 'absolute',
    bottom: 45,
    left: '10%',
    width: '75%',
    boxSizing: 'border-box',
    textAlign:'center'
  },
  coverTextContainer_5: {
    position: 'absolute',
    bottom: 80,
    left: '5%',
    width: '75%',
    boxSizing: 'border-box',
    textAlign:'center'
  },
  coverTextContainer_6: {
    position: 'absolute',
    top: 50,
    right: 0,
    width: '75%',
    boxSizing: 'border-box',
    textAlign:'center'
  },
  coverTextContainer_1: {
    position: 'absolute',
    top: 120,
    left: '10%',
    width: '70%',
    height: '25%', // Usando os 20% restantes para o texto
    boxSizing: 'border-box',
   
  },

  coverText_1: {
    color: 'white',
    fontSize: 35,
    paddingTop: 20,
    fontFamily: 'Roboto',
    
  },
  coverText: {
    color: 'black',
    fontSize: 14,
    textTransform: 'uppercase',
    paddingTop: 10,
    fontFamily: 'Arial_Bold',
  },
  coverText_texto: {
    color: 'black',
    fontSize: 10,
    paddingTop: 13,
    lineHeight: '1.5px', /* Ajuste o valor conforme necessário */
    width:'100%',
    fontFamily: 'Arial',
    
  },
  coverText_texto_2:{
    color: 'black',
    fontSize: 9,
    textTransform: 'uppercase',
    paddingTop: 5,
    width:'100%',
    left: 10,
  },
  coverText_texto_3:{
    color: '#4ac0b5',
    fontSize: 9,
    textTransform: 'uppercase',
    paddingTop: 5,
    width:'100%',
  },
  coverText_texto_4:{
    color: 'white',
    fontSize: 9,
    paddingTop: 5,
    width:'100%',
  },
  coverText_titulo_1: {
    color: 'black',
    fontSize: 18,
    textTransform: 'uppercase',
    paddingTop: 10,
    fontFamily: 'Arial_Bold',
  },
  coverText_titulo_2: {
    color: 'black',
    fontSize: 13,
    textTransform: 'uppercase',
    paddingTop: 20,
    fontFamily: 'Arial_Bold',
  },
  coverText_titulo_3: {
    color: 'black',
    fontSize: 11,
    textTransform: 'uppercase',
    paddingTop: 15,
    fontFamily: 'Arial_Bold',
  },
  titlePage:{
    textAlign: 'center',
  },
  titlePage_2:{
    fontSize: 25,
    textAlign: 'center',
    marginBottom: 30,
    color: '#646464',
  },
  coverText4: {
    color: '#cccccc',
    fontSize: 17,
    paddingTop: 20,
  },
  coverText2: {
    paddingTop: 10,
    left:20,
    width:'90%',
    right:20,
    borderBottomWidth: 2,
    borderBottomColor: '#68472c', // Adicione esta linha para a cor da borda
  },

  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderRadius: 20,
    width:'100%'
  },

  footer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  
  /*tabela*/
  carac_emissao:{
    paddingLeft:40,
    paddingRight:60,
    marginTop: '80px',
    marginBottom: '80px',
  },
  carac_emissao_item:{
    borderColor: '#c6c6c6', // Cor da borda
    borderRight: 2, // Borda no lado direito
    borderLeft: 2, // Borda no lado esquerdo
    borderTop: 2,   // Borda na parte superior
    width:'30%',
    padding: 5,
  },
  carac_emissao_item_last:{
    borderColor: 'black', // Cor da borda
    borderRight: 2, // Borda no lado direito
    borderLeft: 2, // Borda no lado esquerdo
    borderBottom: 2,   // Borda na parte superior
    borderTop: 2,
    width:'30%',
    padding: 5
  },
  carac_emissao_item_last_2:{
    borderColor: 'black', // Cor da borda
    borderRight: 2, // Borda no lado direito
    borderBottom: 2,   // Borda na parte superior
    borderTop: 2,
    width:'70%',
    padding: 5
  },
  carac_emissao_item_2:{
    borderColor: '#4f4f4f', // Cor da borda
    borderRight: 2, // Borda no lado direito
    borderTop: 2,   // Borda na parte superior
    width:'70%',
    padding: 5
  },
  carac_emissao_line:{
    flexDirection: 'row', // Coloca os elementos lado a lado
    justifyContent: 'space-between', // Distribui espaço entre os elementos
  },
  carac_emissao_line_2:{
    flexDirection: 'row', // Coloca os elementos lado a lado
    justifyContent: 'space-between', // Distribui espaço entre os elementos
    backgroundColor:'#cccccc',
  },
  footer:{
    height:'50px',
    backgroundColor:'#262626',
    textAlign: 'center',
    color: 'white',
    padding: 10,
    fontSize: 14,
    bottom:3,
    position:'absolute',
    width:'100%',
  },
  data_proposta:{
    fontSize: 13,
    marginLeft: 'auto',
    paddingRight: 10,
  },
  contatos_proposta:{
    fontSize: 13,
    marginLeft: 'auto',
    paddingRight: 10,
    marginTop:50,
  },
  container: {
    padding: 10,
    marginLeft:30,
    marginRight: 30,
    color:'#636363'
  },
  listItem: {
    marginBottom: 10,
  },
  borderBottom_tile: {
    borderBottom: 1,
    borderColor: '#646464',
    width: '100%',
    marginBottom: 10,
  },

  subtitulo:{
    fontWeight: 'bold', // Adicionando negrito
    marginBottom: 10,
    borderBottom: 2,
    width:'42%',
    color:'#464646',
    borderColor: '#464646', // Cor da borda
  },
  subtitulo_2:{
    color: '#323232', // Cor da borda
  },
  subContainer: {
    position:'absolute',
    flexDirection: 'row',
    top:50,
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignContent: 'space-evenly',
  },
  text: {
    textAlign: 'center',
  },
  textContainer: {
    justifyContent: 'center',
    width: 300,
    height: 60,
    padding: 10,
    margin: 15,
    borderRadius: 5,
    backgroundColor: '#4ac0b5',
    color: 'white'
 },
 table: {
    width: '95%',
    alignSelf: 'center'
 },
 tr: {
    flexDirection: 'row',
    borderBottomColor: 'black',
    borderBottomWidth: 1,
 },
 centralizadoTh: {
    textAlign: 'center',
    fontWeight: 'bold',
    flex: 1, // Adicionado flex
    height: 50,
    verticalAlign: "center",
    paddingTop: 10,
 },
 centralizado: {
    textAlign: 'center',
    fontSize: 17,
    fontWeight: 'extrabold',
    paddingTop: 20,
    paddingBottom: 20,
    flex: 1, // Adicionado flex
    paddingRight: '7px'
},
alinhamentoDireitaTd: {
    textAlign: 'right',
    fontSize: 16,
    paddingTop: 20,
    paddingBottom: 20,
    flex: 1, // Adicionado flex
    paddingRight: '7px'
},
footer_table:{
  marginVertical: 5, // Ajuste essa propriedade para o espaçamento desejado
},
 rodape: {
    marginTop: 10, // Ajuste conforme necessário
    borderTopWidth: 1, // Adiciona uma borda superior ao rodapé
    paddingTop: 5, // Ajuste conforme necessário
    alignItems: 'center',
  },
  textoRodape: {
    fontSize: 12,
    color: 'gray', // Ajuste a cor conforme necessário
  },

   
});

const MyPDFViewer = ({ onClose ,dadosProposta }) => {
    //const coverImage = capaImage;
    //const logo = logo;
    const dataAtual = new Date();
    const allInfoArray1 = JSON.parse(localStorage.getItem('allInfoArray'));
    const allInfoArray2 = useSelector((state) => state.finalTotal.finalTotal)
    const allInfoArray = allInfoArray1 || allInfoArray2
    const anoAtual = dataAtual.getFullYear();
    const storedData = JSON.parse(localStorage.getItem('formValues'));
    const [emissaoPorcento, setEmissaoPorcento] = useState(storedData ? storedData.emissaoPorcento : '');
  //  const [parcelas, setParcelas] = useState(storedData ? storedData.parcelas : '');
  //  const [jurosAA, setJurosAA] = useState(storedData ? storedData.jurosAA : '');
    const paisagemWidth = 15; // polegadas
    const paisagemHeight = 8.5; // polegadas
   const fonts = { Roboto: { normal: calibriFont } };
    const getCurrentDate = () => {
    const now = new Date();
        return `${now.getDate().toString().padStart(2, '0')}/${(now.getMonth() + 1).toString().padStart(2, '0')}/${now.getFullYear()}`;
    };
    function chunkArray(arr, chunkSize) {
        const chunks = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            chunks.push(arr.slice(i, i + chunkSize));
        }
        return chunks;
    }
    const calcData = useSelector((state) => state.calcData.calcData);
    const data = calcData.length > 0 ? calcData : sacData
    const chunkedCalcData = chunkArray(data, 7);
    const sacData = useSelector((state) => state.sacData.sacData); // Suponha que a sacData esteja disponível no estado.
    let isFirstPage = true;
   // const data = calcData.length > 0 ? calcData : sacData
   // const [jurosAA, setJurosAA] = useState('');
   
return (
  
    <PDFViewer width={'90%'} height={'85%'} >
      <Document title={`Nota Comercial - ${dadosProposta.NumeroProposta}`}>
        {/* Capa */}
        <Page size="A4"  orientation="landscape" style={styles.page}>
            <View style={styles.coverPage}>
                <Image src={capaImage} style={styles.coverImage} />
            </View>
        </Page>
        {/* Contra Capa */}
          <Page  size="A4" orientation="landscape" style={styles.page}>
           
            <View style={styles.coverPage}>
                <Image src={contraCapa} style={styles.coverImage} />
            </View>
            <View style={styles.coverTextContainer_1} fonts={fonts}>
                 <Text style={styles.coverText_1}>Proposta Comercial</Text >
            </View>
            <View style={styles.coverTextContainer}>
                 <Text style={styles.coverText}>ESCRITURAÇÃO DE ATIVOS – NOTA COMERCIAL </Text >
                <Text style={styles.coverText}>{`${dadosProposta.Solicitante}`} x SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A </Text >
            </View>
           
          </Page>
           {/* Pag 1 */}
           <Page  size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.coverPage}>
                    <Image src={page_1} style={styles.coverImage} />
                </View>
                <View style={styles.coverTextContainer_3}>
                    <Text style={styles.coverText_titulo_1}>Proposta Comercial</Text >
                    <Text style={styles.coverText_titulo_2}>ESCRITURAÇÃO |  SINGULARE | REMUNERAÇÃO </Text >
                    <Text style={styles.coverText_texto}>Para a prestação de serviços de escrituração das Notas Comerciais a remuneração será:</Text >
                    <Text style={styles.coverText_texto}>{`${emissaoPorcento}`} %  sobre o valor da emissão da nota comercial;</Text >
                    <Text style={styles.coverText_titulo_3}>Alíquota de Imposto (no caso de escrituração)</Text >
                    <Text style={styles.coverText_texto}>Os valores referidos acima serão acrescidos dos valores dos tributos que incidem sobre a prestação desses serviços (gross up), tais como: (i) Imposto Sobre Serviços de Qualquer Natureza - ISSQN; (ii) Contribuição para o Programa de Integração Social - PIS; (iii) Contribuição para o Financiamento da Seguridade Social - COFINS; Imposto sobre a Renda e Proventos de Qualquer Natureza – IR e (iv) outros tributos que venham a incidir sobre a remuneração da Singulare, nas respectivas alíquotas vigentes a cada data de pagamento, sendo certo que serão acrescidos aos pagamentos valores adicionais, de modo que a Singulare receba os mesmos valores que seriam recebidos caso nenhum dos impostos elencados neste item.</Text >
                    <Text style={styles.coverText_titulo_3}>Condições de Pagamento</Text >
                    <Text style={styles.coverText_texto}>Para a prestação de serviços de escrituração das Notas Comerciais a remuneração será:</Text >
                    <Text style={styles.coverText_texto}>Os valores deverão ser pagos pelo emissor à:</Text >
                    <Text style={styles.coverText_texto_2}>Singulare Corretora de Títulos e Valores Mobiliários S.A</Text >
                    <Text style={styles.coverText_texto_2}>CNPJ.: 62.285.390/0001-40</Text >
                    <Text style={styles.coverText_texto_2}>Banco 363</Text >
                    <Text style={styles.coverText_texto_2}>Conta Corrente nº:  45-1</Text >
                    <Text style={styles.coverText_texto}>Após o aceite desta proposta será emitido o Termo de Adesão ao Contrato de Prestação de Serviços entre o emissor e a Singulare CTVM;</Text >
                </View>
                <View style={styles.coverTextContainer_4}>
                    <Text style={styles.coverText_texto_3}>A PRESENTE PROPOSTA É INDICATIVA E OS VALORES DEVERÃO SER VALIDADOS NA DATA DA CONTRATAÇÃO DOS SERVIÇOS. VALE RESSALTAR QUE OS TERMOS COMERCIAIS AQUI ESTABELECIDOS PODEM SOFRER ALTERAÇÕES DEVIDO À COMPLEXIDADE DO PRODUTO</Text >
                </View>
          </Page>
           {/* Pag 2 */}
           <Page  size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.coverPage}>
                        <Image src={page_1} style={styles.coverImage} />
                    </View>
                    <View style={styles.subContainer}>
                        <View style={styles.textContainer}>
                                <Text style={styles.text}>Valor Total: {`${allInfoArray[12]}`}</Text>
                        </View>
                        <View style={styles.textContainer}>
                                <Text style={styles.text}>Quantidade de Parcelas: {`${allInfoArray[4]}`}</Text>
                        </View>
                        <View style={styles.textContainer}>
                                <Text style={styles.text}>Taxa de Juros a.m: {`${allInfoArray[10]}%`}</Text>
                        </View>
                        <View style={styles.textContainer}>
                                <Text style={styles.text}>Carência: {`${allInfoArray[19]}`}</Text>
                        </View>
                        <View style={styles.table}>
                                <View style={styles.tr}>
                                    <Text style={styles.centralizadoTh}>Período</Text>
                                    <Text style={styles.centralizadoTh}>Vencimento</Text>
                                    <Text style={styles.centralizadoTh}>Parcela</Text>
                                    <Text style={styles.centralizadoTh}>Amortização</Text>
                                    <Text style={styles.centralizadoTh}>Juros</Text>
                                    <Text style={styles.centralizadoTh}>Saldo Devedor</Text>
                                </View>
                        {chunkedCalcData.map((chunk, pageIndex) => (
                        
                            <View key={pageIndex}> 
                                {chunk.map((data, index) => (
                                  
                                    <View key={index} style={styles.tr}>
                                      {index === 9 ? <Text style={styles.centralizado}>{data}weweqeqweqwewqewqewq</Text> : null}
                                      <Text style={styles.centralizado}>{data[0]}</Text>
                                      <Text style={styles.centralizado}>
                                      {data[1] < 10 ? `0${data[1]}` : data[1]}/{data[2] === '00' ? 12 : data[2]}/{data[3]}
                                      </Text>
                                      <Text style={styles.alinhamentoDireitaTd}>
                                      {data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                      </Text>
                                      <Text style={styles.alinhamentoDireitaTd}>
                                      {data[5].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                      </Text>
                                      <Text style={styles.alinhamentoDireitaTd}>
                                      {data[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                      </Text>
                                      <Text style={styles.alinhamentoDireitaTd}>
                                      {data[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                      </Text>
                                  </View>
                                ))}
                            </View>
                          ))}             
                        </View>
                    </View>
            </Page>
             {/* Pag 1 */}
           <Page  size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.coverPage}>
                    <Image src={page_1} style={styles.coverImage} />
                </View>
                <View style={styles.coverTextContainer_3}>
                    <Text style={styles.coverText_titulo_1}>Proposta Comercial</Text >
                    <Text style={styles.coverText_texto}>Os trabalhos serão formalmente iniciados após assinatura do “de acordo” a esta proposta.</Text >
                    <Text style={styles.coverText_texto}>A presente proposta está sujeita à análise de PLD pelo Compliance da Singulare</Text >
                    <Text style={styles.coverText_texto}>A presente proposta está sujeita à aprovação do Jurídico da Singulare.</Text >
                </View>
                <View style={styles.coverTextContainer_5}>
                    <Text style={styles.coverText_texto_3}>________________________________________</Text >
                    <Text style={styles.coverText_texto_2}>Assinatura e carimbo</Text >
                    <Text style={styles.coverText_texto_2}>Data ____/____/____</Text >
                </View>
            </Page>
            {/* Final */}
            <Page  size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.coverPage}>
                        <Image src={final} style={styles.coverImage} />
                    </View>
                    <View style={styles.coverTextContainer_6}>
                        <Text style={styles.coverText_texto_3}>Escrituração de Ativos</Text >
                        <Text style={styles.coverText_texto_4}>escrituracao@singulare.com.br</Text >
                        <Text style={styles.coverText_texto_4}>Tel.: (11) 2827-3596</Text >
                    </View>
                    
                    
            </Page>
      </Document>
    </PDFViewer>
  );
};

export default MyPDFViewer;
