import React, { useState } from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import '../styles/Employee.css'
import { formatCpf, isValidCPF, isValidEmail } from "../utils/Tools";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { BiArrowBack } from "react-icons/bi";

const AddEmployee = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const showError = (message) => {
        setErrorMessage(message);
    };
    const showSuccess = (message) => {
        setSuccessMessage(message);
    };
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    const handleEmployee = async () => {
        if (nome.trim().length < 2) {
            showError('Nome deve ter pelo menos 2 caracteres.');
            return;
        }

        // Validar o campo Email
        if (!isValidEmail(email)) {
            showError('Email inválido. Por favor, insira um email válido.');
            return;
        }

        // Validar o campo CPF
        if (!isValidCPF(cpf)) {
            showError('CPF inválido. Por favor, insira um CPF válido.');
            return;
        }

        // Validar o campo Senha
        if (password.length < 6) {
            showError('A senha deve ter pelo menos 6 caracteres.');
            return;
        }
        const role = 'Employee'
        try {
            let response = await fetch(process.env.REACT_APP_BASE_URL +'/employee/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userRef: user.data.email,
                    nome: nome,
                    email: email,
                    cpf: cpf,
                    role: role,
                    password: password,
                })
            });

            if (response.ok) {
                showError(false)
                showSuccess('Cadastro Realizado Com Sucesso!');
                setTimeout(() => {
                    navigate('/inicio'); // Redirecionar após um pequeno atraso
                }, 3000);
            } else {
                console.log('Error:', response.status)
                if (response.status === 408) {
                    showError('Email já cadastrado!');
                } else if (response.status === 409) {
                    showError('CPF já cadastrado!');
                } else if (response.status === 411) {
                    showError('Email Cadastrado como Empresa!');
                }
                else {
                    showError('Erro interno do servidor.');
                }
            }
        } catch (error) {
            console.error(error);
            showError(`Erro ao processar a solicitação. ${error}`);
        }
    }
    return (
        <body className="employeeBody">
            <div className="fixed">
                <Header selected={'master'} />
            </div>
            <main className="employeeMain">
                <div className="ahead">
                    <h2 className="formH21" style={{ textAlign: "center", marginLeft: '10rem', paddingLeft: 0 }}>
                        Gerenciar usuários
                    </h2>
                    <div className="div2">
                        <h3 className="formH22">Bem Vindo, {user.data.razaoSocial}</h3>
                    </div>
                </div>
                <form className="employeeForm">
                    <div className="formTop" onClick={() => navigate('/inicio')}>
                        <BiArrowBack /> <h4 className="formH1">Adicionar colaborador</h4></div>

                    <div className="employeeRow">
                        <div className="employeeColumn">
                            <label className="employeeLabel">
                                Nome
                            </label>
                            <input
                                placeholder="Insira o nome do colaborador"
                                type="text"
                                className="employeeInput"
                                onChange={(e) => setNome(e.target.value)} />
                        </div>
                        <div className="employeeColumn">
                            <label className="employeeLabel">
                                CPF
                            </label>
                            <input
                                placeholder="000.000.000-00"
                                className="employeeInput"
                                value={formatCpf(cpf)}
                                onChange={(e) => setCpf(e.target.value)}
                                maxLength={14} />
                        </div>
                        <div className="employeeColumn">
                            <label className="employeeLabel">
                                E-mail
                            </label>
                            <input
                                placeholder="Insira o e-mail de acesso do colaborador"
                                className="employeeInput"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="employeeColumn">
                            <label className="employeeLabel">
                                Crie uma senha de acesso
                            </label>
                            <div className='divEmployeePassword'>

                                <input
                                    placeholder="A senha deve ter pelo menos 6 caracteres"
                                    className="employeeInputPassword"
                                    onChange={(e) => setPassword(e.target.value)}
                                    type={showPassword ? 'text' : 'password'}
                                />
                                <div onClick={handleShowPassword} className='iconEmployee'>
                                    {showPassword ? <RiEyeFill /> : <RiEyeOffFill />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="employeeButtons">
                        <button onClick={() => { navigate('/home') }} type="button" className="employeeButton2">Cancelar</button>
                        <button onClick={handleEmployee} type="button" className="employeeButton1">Adicionar Colaborador</button>
                    </div>
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    {successMessage && <div className="success-message">{successMessage}</div>}
                </form >
            </main>
        </body>
    )
}

export default AddEmployee;